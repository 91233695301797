import React, { useContext, useEffect, useState  } from 'react';
import { Helmet } from "react-helmet";
import { LanguageContext, WhiteLabelContext,GeolocationContext } from "ToolboxUtils/web/context/context";
import CircuitsChoiceView from 'ToolboxComponents/webapp/pages/circuits-choice-view/circuits-choice-view';
import Loader from 'ToolboxComponents/commons/loader/loader';
import useIsContentLoaded from 'ToolboxUtils/web/hooks/use-is-content-loaded';
import useApiChuchoteurs from 'ToolboxUtils/web/hooks/use-api-chuchoteurs';

const HeartstoppersChoice = props => {
  const [language] = useContext(LanguageContext).usePath();
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [coordinates] = useContext(GeolocationContext).usePath('coordinates');

  const circuits = useApiChuchoteurs.get(`/products`, {
    params: { filter: 'homepage-heartstoppers-themes', language: language, parentWhiteLabelId: whiteLabel.id, whiteLabelId: whiteLabel.privatePartnerId,latitude: coordinates.latitude,
    longitude: coordinates.longitude }
  });

  useIsContentLoaded(circuits.isComplete);
  

  return (
    <>
      {circuits.isComplete
        ? <CircuitsChoiceView circuits={circuits.data} title={"Nos coups de coeur"}/>
        : <Loader />
      }
    </>
  );
}

export default HeartstoppersChoice;
